import shopifyGenericDefaults from '../shopify-generic/config.js';
import { addToCart } from './cart.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').template =
  'fitmentSearch/homeVehicleWidget';

const getActiveCurrency = () => globalThis.Shopify.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;
  const currency = getActiveCurrency();

  const formattedPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
    currencyDisplay: 'symbol',
  }).format(price);

  return `${formattedPrice} ${currency}`;
}

window.Convermax.addToCart = addToCart;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  defaultView: 'grid-3',
  product: {
    ...shopifyGenericDefaults.product,
    noImageSrc: 'https://cdn.shopify.com/s/files/1/0707/2658/2497/files/no-image-product.png',
    formatPrice,
  },
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
};
