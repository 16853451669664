//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-919:_5016,_8200,_6284,_8260,_8248,_9840,_2996,_4596;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-919')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-919', "_5016,_8200,_6284,_8260,_8248,_9840,_2996,_4596");
        }
      }catch (ex) {
        console.error(ex);
      }