//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-919:_8776,_624,_9220,_8444,_2015,_1372,_884,_7020;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-919')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-919', "_8776,_624,_9220,_8444,_2015,_1372,_884,_7020");
        }
      }catch (ex) {
        console.error(ex);
      }