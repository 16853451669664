function getSectionInnerHTML(html, selector = '.shopify-section') {
  return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML;
}

function handleBodyClick(evt) {
  if (
    evt.target !== document.getElementById('mini-cart-drawer') &&
    !evt.target.closest('cart-notification') &&
    !evt.target.closest('#quickViewWrapper')
  ) {
    document.getElementById('mini-cart-drawer').classList.remove('active');
    document.querySelector('body').classList.remove('added__overlay');
    document.body.removeEventListener('click', handleBodyClick);
  }
}

function cartNotificationOpen() {
  const cartNotification = document.getElementById('mini-cart-drawer');

  cartNotification.classList.add('animate', 'active');
  document.querySelector('body').classList.add('added__overlay');
  cartNotification.addEventListener(
    'transitionend',
    () => {
      cartNotification.focus();
    },
    {
      once: true,
    },
  );
  document.body.addEventListener('click', handleBodyClick);
}

function renderContents(parsedState) {
  ['mini-cart-drawer', 'cart-notification-count'].forEach((section) => {
    const renderSelector = document.getElementById(section);
    const renderCartItemCount = document.getElementById('cart-notification-count-mobile');

    if (renderSelector) {
      renderSelector.innerHTML = getSectionInnerHTML(parsedState.sections[section]);
    }

    if (renderCartItemCount) {
      renderCartItemCount.innerHTML = getSectionInnerHTML(parsedState.sections['cart-notification-count']);
    }
  });

  cartNotificationOpen();
}

export function addToCart(evt) {
  evt.preventDefault();

  const form = evt.currentTarget;
  const cartNotification = document.querySelector('cart-notification');
  const quickViewWrapper = document.getElementById('quickViewWrapper');
  const submitButton = form.querySelector('[type="submit"]');

  submitButton.setAttribute('disabled', true);
  submitButton.classList.add('loading');

  const config = {
    method: 'POST',
    headers: {
      'Accept': `application/javascript`,
      'X-Requested-With': 'XMLHttpRequest',
    },
  };

  const formData = new FormData(form);
  if (cartNotification) {
    formData.append('sections', 'mini-cart-drawer,cart-notification-count');
  }

  formData.append('sections_url', window.location.pathname);
  config.body = formData;

  fetch('/cart/add', config)
    .then((response) => response.json())
    .then((parsedState) => {
      if (parsedState.status) {
        return;
      }
      if (cartNotification) {
        renderContents(parsedState);
      }
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      submitButton.classList.remove('loading');
      submitButton.removeAttribute('disabled');
      quickViewWrapper?.classList.remove('show__modal');
      document.body.classList.remove('overflow-hidden');
    });
}
